<template>
  <div class="sp-list-item" :class="modifiers" :data-value="value">
    <sp-divider class="sp-list-item__divider" />
    <div class="sp-list-item__prepend">
      <slot name="prepend">
        <sp-icon v-if="prependIcon" :name="prependIcon" class="sp-list-item__prepend-icon" />
        <sp-icon v-else-if="prependIconUrl" :url="prependIconUrl" class="sp-list-item__prepend-icon" />
      </slot>
    </div>
    <div class="sp-list-item__content">
      {{ title }}
      <div v-if="subtitle" class="sp-list-item__subtitle">
        {{ subtitle }}
      </div>
    </div>
    <div class="sp-list-item__append">
      <slot name="append">
        <sp-icon v-if="props.appendIcon" :name="props.appendIcon" class="sp-list-item__append-icon" />
      </slot>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { toBoolean } from "../../utils/props";

const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
  title: {
    type: [String, Number],
    default: undefined,
  },
  subtitle: {
    type: [String, Number],
    default: undefined,
  },
  value: {
    type: [String, Number, Boolean, Object, Array],
    default: undefined,
  },
  /**
   * Icon to prepend to the list item.
   *
   * @type {String}
   * @default undefined
   */
  prependIcon: {
    type: String,
    default: undefined,
  },
  /**
   * Icon URL to prepend to the list item.
   *
   * @type {String}
   * @default undefined
   */
  prependIconUrl: {
    type: String,
    default: undefined,
  },
  /**
   * Icon to prepend to the list item when active.
   * If not provided, the prepend icon will be the same as the prepend icon.
   *
   * @type {String}
   * @default undefined
   */
  activePrependIcon: {
    type: String,
    default: undefined,
  },
  appendIcon: {
    type: String,
    default: undefined,
  },
  /**
   * If true, the list item must consider the left indentation if no prepend icon is present.
   *
   * @type {Boolean}
   * @default false
   */
  leftIndentation: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * If true, the list item must consider the right indentation if no append icon is present.
   *
   * @type {Boolean}
   * @default false
   */
  rightIndentation: {
    type: [Boolean, String],
    default: false,
  },
  readonly: {
    type: [Boolean, String],
    default: false,
  },
});

const prependIcon = computed(() => (toBoolean(props.active) ? props.activePrependIcon : props.prependIcon));

const modifiers = computed(() => ({
  "--left-indentation": toBoolean(props.leftIndentation),
  "--right-indentation": toBoolean(props.rightIndentation),
}));
</script>

<style>
:host {
  display: block;

  --list-item-background-color: var(--sp-comp-list-item-background-color, transparent);
  --list-item-hover-background-color: var(--sp-comp-list-item-hover-background-color, #eee);
  --list-item-active-background-color: var(--sp-comp-list-item-active-background-color, transparent);
  --list-item-focused-background-color: var(
    --sp-comp-list-item-focused-background-color,
    var(--list-item-hover-background-color)
  );
  --list-item-column-gap: var(--sp-comp-list-item-column-gap, 0.25rem);
  --list-item-min-height: var(--sp-comp-list-item-min-height, 2rem);
  --list-item-prepend-icon-fill: var(--sp-comp-list-item-prepend-icon-fill, #bbb);
  --list-item-append-icon-fill: var(--sp-comp-list-item-append-icon-fill, #bbb);
  --list-item-icon-size: var(--sp-comp-list-item-icon-size, 2rem);

  --display-divider: var(--sp-comp-list-item-display-divider, block);
  --focus-outline: var(--sp-comp-list-item-focus-outline, --sp-sys-focus-outline, none);
}

:host(:hover:not([active]):not([readonly])),
:host(:focus:not([active]):not([readonly])),
:host(:focus-visible:not([active]):not([readonly])) {
  outline: var(--focus-outline);
  --divider-width: 100%;
  --sp-comp-divider-color: var(--list-item-background-color);
  --divider-left: 0;
}

:host(:focus:not([readonly])),
:host(:focus-visible:not([readonly])) {
  outline: none;
  --list-item-background-color: var(--list-item-focused-background-color);
}

:host(:hover:not([active]):not([readonly])) {
  --list-item-append-icon-fill: var(--sp-comp-list-item-prepend-icon-fill-hover, --list-item-append-icon-fill);
  --list-item-background-color: var(--list-item-hover-background-color);
}

:host([active]:not([readonly])) {
  --list-item-prepend-icon-fill: var(--sp-comp-list-item-active-icon-fill, var(--sp-sys-color-success, green));
  --list-item-background-color: var(--list-item-active-background-color);
  --display-divider: var(--sp-comp-list-item-display-divider, block);
}

:host([active]:hover:not([readonly])) {
  --display-divider: var(--sp-comp-list-item-display-divider, block);
}
</style>

<style scoped lang="scss">
.sp-list-item {
  --list-item-line-height: var(--sp-comp-list-item-line-height, 1.25);
  --list-item-padding-inline: var(--sp-comp-list-item-padding-inline, 0.75rem);
  --list-item-padding-block: var(--sp-comp-list-item-padding-block, 0.375rem);
  --list-item-prepend-min-width: var(--sp-comp-list-item-prepend-min-width, 0);

  position: relative;
  display: grid;
  grid-template-columns: max-content auto max-content;
  grid-template-areas: "prepend content append";
  align-items: start;
  padding: 0;
  padding-block: var(--list-item-padding-block);
  cursor: pointer;
  transition: background-color 0.2s ease;
  min-height: var(--list-item-min-height);

  background-color: var(--list-item-background-color);

  &.--left-indentation {
    --list-item-prepend-min-width: 32px;
  }
}

.sp-list-item__subtitle {
  display: block;
  font-size: var(--sp-comp-list-item-subtitle-font-size, 0.875rem);
  color: grey;
}

.sp-list-item__prepend {
  min-width: var(--list-item-prepend-min-width);
  grid-area: prepend;
  margin-right: var(--sp-comp-list-item-prepend-margin-right, var(--list-item-column-gap));
  margin-left: var(--sp-comp-list-item-prepend-margin-left, var(--list-item-padding-inline));

  sp-icon {
    --fill: var(--list-item-prepend-icon-fill);
    --size: var(--list-item-icon-size);
  }
}

.sp-list-item__content {
  --icon-size: 2rem;
  grid-area: content;
  min-height: var(--icon-size);
  padding-right: var(--list-item-column-gap);
  place-content: center;
  font-family: var(--sp-comp-list-item-content-font-family, var(--sp-sys-font-family-normal));
  white-space: normal;
}

.sp-list-item__append {
  grid-area: append;
  margin-right: var(--sp-comp-list-item-append-margin-right, var(--list-item-padding-inline));
  margin-left: var(--list-item-column-gap);

  sp-icon {
    --fill: var(--list-item-append-icon-fill);
    --size: var(--list-item-icon-size);
  }
}

.sp-list-item__divider {
  display: var(--display-divider);
  position: absolute;
  top: 0;
  left: var(--divider-left, var(--list-item-padding-inline));
  width: var(--divider-width, calc(100% - var(--list-item-padding-inline) * 2));
}
</style>
