<template>
  <component
    :is="contentComponent"
    id="container"
    class="sp-navigation"
    :title="collapsableMenuTitle"
    :class="classModifiers"
    :style="styleVariables"
  >
    <slot class="default-slot" />

    <div v-if="stuckToTop">
      <slot name="stuck-to-top" />
    </div>
  </component>
</template>

<script setup>
/**
 * The SpNavigation component is used to display a navigation bar.
 *
 * @displayName Navigation
 * @group Custom Elements
 * @component sp-navigation
 */

import { computed } from "vue";
import { useBreakpoints } from "../../composables/breakpoints";
import { toBoolean } from "../../utils/props";

const { isGtSmScreen } = useBreakpoints();

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  /**
   * If true the navigation will enable a slot for when it is stuck to the top of the page.
   * This can be used to display additional content when the navigation is stuck to the top.
   *
   * @type {Boolean}
   * @default false
   */
  stuckToTop: {
    type: [Boolean, String],
    default: false,
  },

  /**
   * If true the navigation will be collapsable on small screens.
   * This can be used to display a menu button that will show the navigation when clicked.
   *
   * @type {Boolean}
   * @default false
   */
  collapsable: {
    type: [Boolean, String],
    default: false,
  },

  /**
   * The title to display on the menu button when the navigation is collapsable.
   *
   * @type {String}
   * @default undefined
   */
  collapsableMenuTitle: {
    type: String,
    default: undefined,
  },
});

const classModifiers = computed(() => ({
  "--is-stuck-to-top": toBoolean(props.stuckToTop),
}));

const styleVariables = computed(() => ({
  "--direction": isGtSmScreen.value ? "row" : "column",
}));

const contentComponent = computed(() => {
  if (toBoolean(props.collapsable) && !isGtSmScreen.value) {
    return "sp-expandable-area";
  }
  return "nav";
});
</script>

<style>
:host {
  display: block;
}
</style>

<style scoped lang="scss">
.sp-navigation {
  --direction: row;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  flex-direction: var(--direction);
  width: 100%;
}
.default-slot {
  display: var(--sp-ce-navigation-default-slot-display, flex);
  width: var(--sp-ce-navigation-default-slot-width, 100%);
}
</style>
